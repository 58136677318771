import {getCoachCamRef, getCoachCamAudioTracks, getCoachCamStream} from "./userMedia";
import {addSegmentToRegistry} from "./recordedSegmentRegistry";

export type DrawingTool = "PENCIL" | "LINE" | "CIRCLE" | "ARROW" | "NONE"

export interface TwoPointDrawingShape {
    fromx: number,
    fromy: number,
    tox: number,
    toy: number,
    type: DrawingTool
}


export interface CanvasDimensions {
    w: number
    h: number
    sourceWidth: number
    sourceHeight: number
    scale: number
    top: number
    left: number
}

export interface DrawingMouseButtonPoint {
    x: number,
    y: number,
    tool: DrawingTool
}

export type CanvasType = "RECORDING" | "DISPLAY"

let canvas: HTMLCanvasElement|undefined
// let displayCanvas: HTMLCanvasElement|undefined
let ctx: CanvasRenderingContext2D|undefined
let dctx: CanvasRenderingContext2D|undefined    //display canvas
let canvasVideoStream: MediaStream
let canvasRecorder: MediaRecorder
const audioCtx = new AudioContext()
let recordedChunks: Blob[] = []
let recordingStartTime: number
let recordingEndTime: number
const noPoint: DrawingMouseButtonPoint = { x: 0, y: 0, tool: "NONE"}
let lastMouseButtonDown: DrawingMouseButtonPoint = noPoint
let lastMouseButtonUp: DrawingMouseButtonPoint = noPoint

let isIntro: Boolean = false
let rcd: CanvasDimensions   //recordingCanvasDimensions
let dcd: CanvasDimensions   //displayCanvasDimensions
let twoPointShapes: TwoPointDrawingShape[] = []
let twoPointShapeInProgress: TwoPointDrawingShape | undefined = undefined
let redoTwoPointShapes: TwoPointDrawingShape[] = []
let introBackground = new Image()
let introBackgroundColor: string = "#FFFFFF"

const mirrorX: number = 1 // change to -1 to flip horizontally

let delayNode: DelayNode | undefined; // Variable to hold the reference to the DelayNode
let canvasRecordStartTime: number | undefined = undefined
let audioRecordStartTime: number | undefined = undefined

export const setCanvasRecordStartTime = (newTime: number|undefined) => {
    canvasRecordStartTime = newTime
}
export const getCanvasRecordStartTime = (): number|undefined => {
    return canvasRecordStartTime
}

export const setAudioRecordStartTime = (newTime: number|undefined) => {
    audioRecordStartTime = newTime
}

export const getAudioDelay = (): number => {
    if(audioRecordStartTime === undefined || canvasRecordStartTime === undefined)
        return 0
    else{
        const delay = (canvasRecordStartTime - audioRecordStartTime) / 1000
        if(delay < 0)
            return 0
        else
            return delay
    }
}

export const updateDelayTime = (): void => {
    if (delayNode) {
        const delay = getAudioDelay()
        console.log("the audio delay has been updated to: ", delay, "s")
        delayNode.delayTime.value = delay
    } else {
        console.log("DelayNode not initialized");
    }
};

export const updateIntroBackgroundByHandle = (handle: string, color: string = "#FFFFFF") => {
    introBackgroundColor = color
    if(handle === "Fulham_Academy"){
        introBackground.src = "/club_logos/LOGO-FFC.png"
    }else if(handle === "PUFC_Academy"){
        introBackground.src = "/club_logos/LOGO-PUFC.png"
    }else if(handle === "QPR_Academy") {
        introBackground.src = "/club_logos/LOGO-QPR.png"
    }else if(handle === "Ian_McClurgLPC") {
        introBackground.src = "/club_logos/LOGO-Ian_McClurgLPC.png"
    }else{
        introBackground.src = "/club_logos/via_logo.svg"
        introBackgroundColor = "#000000"
    }
    introBackground.height = 480
    introBackground.width = 480
}

export const updateIntroBackgroundSrc = (newSrc: string) => {
    console.log(newSrc)

    // CORS IS NEEDED WHEN USING A REMOTE IMAGE:
    // console.log(newSrc)
    // console.log(getHostnameWithProtocolAndPort())
    // console.log(getCrossOriginAttributeImg(newSrc))
    // console.log("crossorigin")
    // const imageDescription = "Club Logo"
    // let downloadedImg = new Image();
    // downloadedImg.crossOrigin = getCrossOriginAttributeImg(newSrc)
    // downloadedImg.addEventListener("load", ()=>{}, false);
    // downloadedImg.alt = imageDescription;
    // downloadedImg.src = newSrc + '?noCache=' + Math.random().toString();
    //
    // introBackground = downloadedImg as HTMLImageElement
    // console.log(typeof introBackground)
}
export const updateIntroBackgroundColor = (newColor: string) => {
    introBackgroundColor = newColor
}

export const stopCanvasVideoStream = () => {
    if(canvasVideoStream){
        canvasVideoStream.getTracks().forEach(track => {track.stop()})
    }
}

export const setIsIntro = (newVal: Boolean) => {
    isIntro = newVal
}

export const clearTwoPointShapes = () => {
    twoPointShapes = []
    redoTwoPointShapes = []
}

export const clearTwoPointShapeInProgress = () => {
    twoPointShapeInProgress = undefined
}

export const updateTwoPointShapeInProgress = (tps: TwoPointDrawingShape ) => {
    twoPointShapeInProgress = tps
}


export const recordCanvasMouseButtonDown = (point: DrawingMouseButtonPoint): void => {
    lastMouseButtonDown = point
}

export const undoDrawingShape = (): void => {
    const lastShape = twoPointShapes.pop()
    if(lastShape)
        redoTwoPointShapes.push(lastShape)
}

export const redoDrawingShape = (): void => {
    const lastUndone = redoTwoPointShapes.pop()
    if(lastUndone)
        twoPointShapes.push(lastUndone)
}

export const resetDrawings = (): void => {
    twoPointShapes = []
    redoTwoPointShapes = []
}

export const clearButtonDownPoint = ():void => {
    lastMouseButtonDown = noPoint
}

export const recordCanvasMouseButtonUp = (point: DrawingMouseButtonPoint, inProgress: boolean = false): void => {
    console.log(point)
    console.log(lastMouseButtonUp, lastMouseButtonDown)
    let tool: DrawingTool = "NONE"
    lastMouseButtonUp = point
    if(point.tool === "ARROW" && lastMouseButtonDown.tool === "ARROW" ){
        tool = "ARROW"
        console.log("adding ARROW")
    }else if(point.tool === "LINE" && lastMouseButtonDown.tool === "LINE"){
        tool = "LINE"
        console.log("adding LINE")
    }else if(point.tool === "CIRCLE" && lastMouseButtonDown.tool === "CIRCLE"){
        tool = "CIRCLE"
        console.log("adding CIRCLE")
    }
    const newTPS = {
        fromx: lastMouseButtonDown.x,
        fromy: lastMouseButtonDown.y,
        tox: point.x,
        toy: point.y,
        type: tool
    } as TwoPointDrawingShape
    if(inProgress){
        updateTwoPointShapeInProgress(newTPS)
    }else{
        clearTwoPointShapeInProgress()
        twoPointShapes.push(newTPS)
        clearButtonDownPoint()
    }
}

export const setRecordingCanvas = (theCanvas: HTMLCanvasElement, theCanvasContext: CanvasRenderingContext2D): void => {
    canvas = theCanvas
    ctx = theCanvasContext
}

export const setDisplayCanvas = (theCanvas: HTMLCanvasElement, theCanvasContext: CanvasRenderingContext2D): void => {
    // displayCanvas = theCanvas
    dctx = theCanvasContext
}

export const clearRecordingCanvas = () => {
    canvas = undefined
    ctx = undefined
}

function canvas_arrow(context: CanvasRenderingContext2D, fromx: number, fromy: number, tox:number, toy: number) {
    var headlen = 30; // length of head in pixels
    const width_fudge = 8;
    var dx = tox - fromx;
    var dy = toy - fromy;
    var angle = Math.atan2(dy, dx);
    context.moveTo(fromx, fromy);
    // context.lineTo(tox + (width_fudge/2 * Math.cos(angle - Math.PI / 4)), toy + (width_fudge/2 * Math.cos(angle - Math.PI/4)));
    context.lineTo(tox, toy)
    context.moveTo(tox + (width_fudge * Math.cos(angle)), toy + (width_fudge * Math.sin(angle)));
    context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
    context.moveTo(tox + (width_fudge * Math.cos(angle)), toy + (width_fudge * Math.sin(angle)));
    context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
}

function canvas_line(context: CanvasRenderingContext2D, fromx: number, fromy: number, tox:number, toy: number) {
    context.moveTo(fromx, fromy);
    context.lineTo(tox, toy);
}

function canvas_circle(context: CanvasRenderingContext2D, fromx: number, fromy: number, tox:number, toy: number) {
    const r: number = Math.min(Math.hypot(tox-fromx, toy-fromy), fromx)
    context.moveTo(fromx + r, fromy);
    context.arc(fromx, fromy, r, 0, 2 * Math.PI)
}

const drawTwoPointShapes = (context: CanvasRenderingContext2D): void => {
    context.beginPath();

    const tps = twoPointShapeInProgress === undefined ? twoPointShapes  :  twoPointShapes.concat([twoPointShapeInProgress])

    tps.forEach(s => {
        if (s.type === "ARROW") {
            canvas_arrow(context, s.fromx, s.fromy, s.tox, s.toy)
        } else if (s.type === "LINE") {
            canvas_line(context, s.fromx, s.fromy, s.tox, s.toy)
        } else if (s.type === "CIRCLE") {
            canvas_circle(context, s.fromx, s.fromy, s.tox, s.toy)
        }
    })
    context.stroke();
}


export const initCanvasRecorder = (): void => {
    console.log("running initCanvasRecorder")
    console.log("ctx", ctx)
    if(ctx && canvas) {
        ctx.lineWidth = 10
        ctx.lineCap = "round"
        ctx.strokeStyle = "#05FF00"
        // ctx.strokeStyle = "#ba840c"  // via yellow/orange
        console.log('ctx.canvas', ctx.canvas)
        canvasVideoStream = canvas.captureStream(30)
        console.log(canvasVideoStream)
        console.log("has canvasVideoStream?")
        console.log(canvasVideoStream)
        if(!canvasVideoStream){
            console.log("no canvasVideoStream!")
            setTimeout(()=> {initCanvasRecorder()}, 1000)
            return;
        }else{
            console.log("yes!")
        }
        let coachCamAudioTracks = getCoachCamAudioTracks()
        console.log("Try to addTrack..")
        if(coachCamAudioTracks.length){
            console.log("addTrack", coachCamAudioTracks)
            const coachStream = getCoachCamStream()
            if(coachStream){

                //create source
                const source: MediaStreamAudioSourceNode = audioCtx.createMediaStreamSource(coachStream)
                const destination: MediaStreamAudioDestinationNode = audioCtx.createMediaStreamDestination()

                //create delay node
                delayNode = new DelayNode(audioCtx, { delayTime: getAudioDelay(), maxDelayTime: 1 })
                source.connect(delayNode)
                delayNode.connect(destination)
                coachCamAudioTracks = destination.stream.getAudioTracks()
            }
            canvasVideoStream.addTrack(coachCamAudioTracks[0])
        }else{
            console.log("no stream to add")
        }
        console.log(canvasVideoStream)
        const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=h264") ? "video/webm;codecs=h264" : "video/webm"
        const options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 3500000,
            mimeType: mimeType,
        };
        canvasRecorder = new MediaRecorder(canvasVideoStream, options)

        // console.log("canvasRecorder", canvasRecorder)
        canvasRecorder.ondataavailable = (e) => {
            // console.log("available data: ",e.data)
            if(canvasRecordStartTime === undefined){
                const now = performance.now()
                // console.log("updating delay time. Canvas start time: ", now)
                setCanvasRecordStartTime(now)
                updateDelayTime()
            }
            // console.log("available data: x")
            recordedChunks.push(e.data)
        }
        canvasRecorder.onstop = function(e) {
            console.log("recorder stopped");
            console.log(e)
        }
        canvasRecorder.onerror = function(error) {
            console.log("canvasRecorder error");
            alert(error)
            throw error;
        }
        canvasRecorder.onstart = function() {
            console.log('started');
        };
        canvasRecorder.onpause = function() {
            console.log('paused');
        };
        canvasRecorder.onresume = function() {
            console.log('resumed');
        };
    }
}


export const setRecordClip = (clipId: string): void => {

    console.log(clipId)
    console.log("canvasRecorder state", canvasRecorder.state)

    if(canvasRecorder.state === 'inactive'){
        canvasRecorder.start(1000/30)
        // canvasRecorder.start(1000)
        console.log("canvasRecorder.start(1000/30)")
    }
    if(canvasRecorder.state === 'paused'){
        canvasRecorder.resume()
        console.log("canvasRecorder.resume()")
    }
}

export const stopRecordingClip = () => {
    console.log("canvasRecorder.stop()")
    canvasRecorder.stop()
}

export const getReviewVideoUrl = (): string => {
    // const videoBlob = new Blob(getAllChunks(),  { type: "video/webm" })
    const videoBlob = new Blob(recordedChunks,  { type: "video/webm" })
    return URL.createObjectURL(videoBlob)
}

export const resetRecordedChunks = (): void => {
    setAudioRecordStartTime(undefined)
    setCanvasRecordStartTime(undefined)
    recordedChunks.length = 0
}

export const setRecordingStartTime = (): number => {
    recordingStartTime = Date.now()
    return recordingStartTime
}
export const setRecordingEndTime = (): number => {
    recordingEndTime = Date.now()
    return recordingEndTime
}

export const getLastRecordingLength = (): number => {
    return Math.abs((recordingEndTime - recordingStartTime)/1000)
}

export const getLastRecordingLengthString = (): string => {
    return getLastRecordingLength().toFixed(2)
}

export const getRecordedSegmentUrl = (): string => {
    // const videoBlob = new Blob(getAllChunks(),  { type: "video/webm" })
    const videoBlob = new Blob(recordedChunks,  { type: "video/webm" })
    const url =  URL.createObjectURL(videoBlob)
    const result = addSegmentToRegistry(url, recordedChunks, {type: "video/webm"} as FilePropertyBag)
    console.log(result.msg)
    return url
}

export const setCanvasDimensions = (newDimensions: CanvasDimensions, canvasType: CanvasType): void => {
    if(canvasType === "RECORDING"){
        rcd = {
            ...newDimensions,
            top: (newDimensions.h - newDimensions.sourceHeight * newDimensions.scale) / 2,
            left: (newDimensions.w - newDimensions.sourceWidth * newDimensions.scale) / 2
        }
    }else if(canvasType === "DISPLAY"){
        dcd = {
            ...newDimensions,
            top: (newDimensions.h - newDimensions.sourceHeight * newDimensions.scale) / 2,
            left: (newDimensions.w - newDimensions.sourceWidth * newDimensions.scale) / 2
        }
    }
}

export const getRecordingCanvasDimensions = (): CanvasDimensions => {
    return rcd
}

export const getDisplayCanvasDimensions = (): CanvasDimensions => {
    return dcd
}


export const getRecordingCanvas = (): CanvasRenderingContext2D|undefined => {
    return ctx
}

export const getDisplayCanvas = (): CanvasRenderingContext2D|undefined => {
    return dctx
}

export const drawVideo = (v: HTMLVideoElement): void => {
    if(ctx) {
        // console.log(v.src)
        if(isIntro){
            const left = (((rcd.w/2) - introBackground.width)/2) + (rcd.w/2)
            const top = (rcd.h - introBackground.height)/2

            //need to normalize image dimensions
            ctx.fillStyle = introBackgroundColor
            ctx.fillRect(0,0, rcd.w, rcd.h)
            ctx.drawImage(introBackground, left, top, introBackground.width, introBackground.height)
        }else{
            ctx.fillStyle = introBackgroundColor
            ctx.fillRect(0,0, rcd.w, rcd.h)
            ctx.drawImage(v, rcd.left, rcd.top, rcd.sourceWidth * rcd.scale, rcd.sourceHeight * rcd.scale)
            drawTwoPointShapes(ctx)
        }
    }
}

export const drawOntoDisplayCanvas = (): void => {
    if(dctx && canvas){
        dctx.fillRect(0,0, dcd.w, dcd.h)
        dctx.drawImage(canvas, dcd.left, dcd.top, dcd.sourceWidth * dcd.scale, dcd.sourceHeight * dcd.scale)
    }
}

export const drawCoachCam = () => {
    const ccr: HTMLVideoElement = getCoachCamRef()
    if(ccr && ctx){
        const rcdw_factor = isIntro ? 0.5 : 0.25;
        const rcdh_factor = isIntro ? 1 : 0.25;
        const widthBasedCamScale = (rcd.w * rcdw_factor) / ccr.videoWidth
        const heightBasedCamScale = (rcd.h * rcdh_factor) / ccr.videoHeight
        const camScale = Math.min(widthBasedCamScale, heightBasedCamScale)
        let dx: number = 10, dy: number = 10
        if(isIntro){
            if(widthBasedCamScale > heightBasedCamScale) {
                //console.log('heightBased')
                dx = ((rcd.w/2) - (ccr.videoWidth * camScale))/2
                dy = 0
            }else{
                //console.log('widthBased')
                dx = 0
                dy = (rcd.h - (ccr.videoHeight * camScale))/2
            }
        }
        ctx.scale(mirrorX,1)
        ctx.drawImage(ccr, dx * -1, dy, ccr.videoWidth * camScale * mirrorX, ccr.videoHeight * camScale)
        ctx.scale(1,1)
    }
}
