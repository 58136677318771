import React from "react";

interface DownloadLinkProps {
    fileUrl: string; // Public file URL
    fileName?: string;
    linkText?: string;
    academyId?: string;
}

export function VideoUrlDownloadLink(props: DownloadLinkProps) {

    const getDefaultFileName = (): string => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        const academyId = props.academyId ? props.academyId + "_" : ""

        return `CF_${academyId}${year}-${month}-${day}-${hours}${minutes}${seconds}.mp4`;

    };

    const handleDownload = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default navigation
        const response = await fetch(props.fileUrl);
        if (!response.ok) {
            throw new Error("Failed to fetch the file.");
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = props.fileName || getDefaultFileName()
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <a
            href={props.fileUrl}
            onClick={handleDownload}
        >
            {props.linkText || "Download MP4"}
        </a>
    )
}

